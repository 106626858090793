import {
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  CssBaseline,
  Stack,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import MuiCard from '@mui/material/Card';
import {
  CircleIcon,
  ConsumerLightTheme,
  OpenInNewIcon,
} from '@verily-src/react-design-system'; // eslint-disable-line node/no-extraneous-import
import {ContentCardSource} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/discover_service';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import SrText from '@verily-src/verily1-verily-me-web-common-typescript/src/components/SrText/SrText';
import useIsMobile from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useIsMobile';
import {useTranslation} from 'react-i18next';
import DiscoverService from '../../services/grpc/DiscoverService';
import {Image} from '../Image/Image';
import styles from './ContentCard.styles';
import WebMDIcon from './WebMDIcon';

export type CardProps = {
  cardId?: string;
  title?: string;
  date?: string;
  contentUrl?: string;
  source?: Source;
  contentImage?: Image;
  loading?: boolean;
};

export type Source = {
  type: ContentCardSource;
  title: string;
};

const ContentCard = function (props: CardProps) {
  const {title, contentImage, contentUrl, source, date, loading} = props;
  const {t} = useTranslation();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const cardIdIdentifier = props.cardId?.split('/')[1]; // Filter ...content/, /version...
  const cardIdVerison = props.cardId?.split('/')[3]; // Filter .../version/

  return (
    <section>
      <ThemeProvider theme={ConsumerLightTheme}>
        <CssBaseline />
        <MuiCard
          sx={{
            width: '100%',
            maxWidth: 572,
            borderRadius: '24px',
            borderColor: theme.palette.background.separator,
            borderWidth: 0.5,
            marginBottom: '8px',
            backgroundColor: theme.palette.background.paper,
          }}
          variant="outlined"
        >
          <CardActionArea
            href={contentUrl || ''}
            onClick={() => {
              if (!props.cardId) return;
              // Record the content card interaction
              DiscoverService.recordContentCardInteraction(props.cardId);
            }}
            target="_blank"
            data-id="content-card"
            pendo-education-card-id={cardIdIdentifier}
            pendo-education-card-title={title}
            pendo-education-card-version={cardIdVerison}
          >
            {/* Screen reader text to inform the user that the link will open in a new window */}
            <SrText text={t('new-window')} />
            <CardMedia
              sx={{
                width: '100%',
                aspectRatio: '16/9',
                backgroundColor: loading
                  ? theme.palette.background.secondaryCanvas
                  : undefined,
              }}
              image={contentImage?.href}
              title={contentImage?.altText}
              data-id="content-card-image"
              tabIndex={-1}
            >
              <Box sx={styles.topElements}>
                {contentUrl && (
                  <Box sx={styles.button}>
                    <Box sx={styles.stateLayer}>
                      <OpenInNewIcon
                        data-id="open-icon"
                        sx={styles.openInNewIcon}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </CardMedia>
            <CardContent>
              <Box>
                <Box>
                  {loading ? (
                    <Stack spacing={0.5} mb={theme.spacing(0.5)}>
                      <PillSkeleton width="95%" height={theme.spacing(2)} />
                      <PillSkeleton width="80%" height={theme.spacing(2)} />
                    </Stack>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: isMobile ? 21 : undefined,
                        color: theme.palette.text.default,
                      }}
                      variant="display6"
                      component="h2"
                      gutterBottom
                      tabIndex={-1}
                    >
                      {title}
                    </Typography>
                  )}
                </Box>
                <Box sx={styles.supportingText}>
                  <Box sx={styles.source}>
                    <Box
                      sx={{
                        ...styles.brandIcon,
                        fill: theme => theme.palette.icon.default,
                      }}
                    >
                      {loading ? (
                        <CircleIcon color="disabled" />
                      ) : (
                        contentSourceToIcon(source?.type)
                      )}
                    </Box>
                    <Box sx={styles.sourceText}>
                      {loading ? (
                        <PillSkeleton width="20%" height={theme.spacing(2)} />
                      ) : (
                        <Typography
                          align="left"
                          variant="body2"
                          tabIndex={-1}
                          color={theme.palette.text.primary}
                        >
                          {source?.title}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box sx={styles.time}>
                    {loading ? (
                      <PillSkeleton
                        width={theme.spacing(4)}
                        height={theme.spacing(2)}
                      />
                    ) : (
                      <Typography
                        align="right"
                        variant="body2"
                        sx={{color: theme.palette.text.muted}}
                        tabIndex={-1}
                      >
                        {date}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </CardActionArea>
        </MuiCard>
      </ThemeProvider>
    </section>
  );
};

const contentSourceToIcon = (source?: ContentCardSource) => {
  switch (source) {
    case ContentCardSource.WEB_MD_VERILY_HEALTH_CLIPS:
    case ContentCardSource.WEB_MD_CONSUMER_HEALTH_LIBRARY:
    case ContentCardSource.WEB_MD:
      return <WebMDIcon />;
    case ContentCardSource.CONTENT_CARD_SOURCE_UNSPECIFIED:
    default:
      return <CircleIcon />;
  }
};

export default ContentCard;
