import {Box} from '@mui/material';
import {useEffect} from 'react';
import {AsyncConversationalAssistantLoader} from '../../components/AsyncConversationalAssistantLoader/AsyncConversationalAssistantLoader';

function ConversationalAssistantPage() {
  useEffect(() => {
    document.body.style.backgroundColor = '#FFFFFF';

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  return (
    <Box data-testid="conversational-assistant-box">
      {<AsyncConversationalAssistantLoader />}
    </Box>
  );
}

export default ConversationalAssistantPage;
