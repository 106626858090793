import {
  Box,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import MuiCard from '@mui/material/Card';
import {InProgressVerilyMeIcon} from '@verily-src/react-design-system';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import useIsMobile from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useIsMobile';
import {useTranslation} from 'react-i18next';
import {Image} from '../Image/Image';

export type TaskCardProps = {
  title?: string;
  dueDateLabel?: string;
  dueDate?: string;
  overdue?: boolean;
  source?: Source;
  status?: string;
  cardIllustrationImage?: Image;
  onClick?: () => void;
  bundleId?: string;
  bundlePlanId?: string;
  bundlePlanName?: string;
  loading?: boolean;
};

export type Source = {
  icon: string;
  title: string;
};

const NonBreakingSpace = '\u00A0';

const TaskCard = function (props: TaskCardProps) {
  const {
    title,
    dueDate,
    dueDateLabel,
    onClick,
    cardIllustrationImage,
    status,
    overdue,
    bundleId,
    bundlePlanId,
    bundlePlanName,
    loading,
  } = props;
  const theme = useTheme();
  const color = theme.palette.highlight?.[1];
  const isMobile = useIsMobile();
  const {t} = useTranslation();

  return (
    <MuiCard
      sx={{
        maxWidth: 572,
        width: '100%',
        borderRadius: '24px',
        borderColor: theme.palette.background.separator,
        borderWidth: 0.5,
        backgroundColor: theme.palette.background.paper,
      }}
      variant="outlined"
    >
      <CardActionArea
        onClick={onClick}
        pendo-bundle-id={bundleId}
        pendo-bundle-plan-id={bundlePlanId}
        pendo-bundle-plan-name={bundlePlanName}
        aria-label={loading ? t('spinner-label') : undefined}
        pendo-bundle-title={title}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1,
            }}
          >
            {status && (
              <InProgressVerilyMeIcon
                sx={{
                  stroke: color,
                  strokeWidth: 0.5,
                }}
              />
            )}
            <Typography
              data-id="task-card-status"
              sx={{
                pl: 0.5,
                fontWeight: 500,
                color: color,
              }}
              variant="body2"
            >
              {status || NonBreakingSpace}
            </Typography>
          </Box>
          <Box
            sx={{
              minHeight: isMobile ? theme.spacing(15) : theme.spacing(14),
            }}
          >
            {loading ? (
              <Stack
                spacing={1.5}
                data-testid="title-skeleton"
                pb={theme.spacing(18.5)}
              >
                <PillSkeleton
                  width={'80%'}
                  height={theme.spacing(2)}
                  animation="wave"
                />
                <PillSkeleton
                  width={'90%'}
                  height={theme.spacing(2)}
                  animation="wave"
                />
                <PillSkeleton
                  width={'70%'}
                  height={theme.spacing(2)}
                  animation="wave"
                />
              </Stack>
            ) : (
              title && (
                <Typography
                  data-id="task-card-title"
                  variant="display6"
                  component="h2"
                  sx={{
                    fontSize: isMobile ? 21 : undefined,
                  }}
                >
                  {title}
                </Typography>
              )
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <Box
              sx={{
                bottom: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                mt: 1,
                flexGrow: 1,
              }}
            >
              {loading ? (
                <Stack spacing={1} data-testid="due-date-skeleton">
                  <PillSkeleton
                    width={'15%'}
                    height={theme.spacing(2)}
                    animation="wave"
                  />
                  <PillSkeleton
                    width={'15%'}
                    height={theme.spacing(2)}
                    animation="wave"
                  />
                </Stack>
              ) : (
                dueDateLabel && (
                  <Typography
                    sx={{
                      display: 'block',
                      color: theme.palette.highlight[1],
                    }}
                    variant="body2"
                  >
                    {dueDateLabel}
                  </Typography>
                )
              )}
              {dueDate && (
                <Typography
                  data-id="task-card-due-date"
                  sx={{
                    fontSize: '18px',
                    // TODO(ONEVERILY-17471): Import Poppins `500` weight font & replace `600`
                    // instances
                    fontWeight: 600,
                    lineHeight: '133%',
                    color: overdue
                      ? theme.palette.error.main
                      : theme.palette.highlight[1],
                  }}
                >
                  {dueDate}
                </Typography>
              )}
            </Box>
            {cardIllustrationImage && (
              <Box
                sx={{
                  height: isMobile ? theme.spacing(16) : theme.spacing(19.5),
                  width: isMobile ? theme.spacing(16) : theme.spacing(19.5),
                }}
              >
                <img
                  src={cardIllustrationImage.href}
                  aria-hidden="true"
                  style={{
                    height: isMobile ? theme.spacing(16) : theme.spacing(19.5),
                    width: isMobile ? theme.spacing(16) : theme.spacing(19.5),
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                  }}
                  data-image-type={cardIllustrationImage.imageType}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </MuiCard>
  );
};

export default TaskCard;
