/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/home/bff/api/v1/discover_service.proto" (package "verily_me.web.home.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.ListContentCardsRequest
 */
export interface ListContentCardsRequest {
    /**
     * BCP-47 language tag the content cards should be rendered in.
     *
     * @generated from protobuf field: string language_tag = 1;
     */
    languageTag: string;
}
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.ListContentCardsResponse
 */
export interface ListContentCardsResponse {
    /**
     * @generated from protobuf field: repeated verily_me.web.home.bff.api.v1.ContentCard content_cards = 1;
     */
    contentCards: ContentCard[];
}
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.RecordContentCardInteractionRequest
 */
export interface RecordContentCardInteractionRequest {
    /**
     * ID of the content card.
     *
     * @generated from protobuf field: string content_card_id = 1;
     */
    contentCardId: string;
}
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.RecordContentCardInteractionResponse
 */
export interface RecordContentCardInteractionResponse {
}
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.ContentCard
 */
export interface ContentCard {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Title of the content card.
     *
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * Should be "WebMD Ignite" for MVP.
     *
     * @generated from protobuf field: string source_name = 3;
     */
    sourceName: string;
    /**
     * Type of source.
     *
     * @generated from protobuf field: verily_me.web.home.bff.api.v1.ContentCardSource content_source = 4;
     */
    contentSource: ContentCardSource;
    /**
     * Bytes of the header image.
     *
     * @generated from protobuf field: bytes header_image = 5;
     */
    headerImage: Uint8Array;
    /**
     * String of elapsed time.
     *
     * @generated from protobuf field: string elapsed_time = 6;
     */
    elapsedTime: string;
    /**
     * String of alternative text.
     *
     * @generated from protobuf field: string alt_text = 7;
     */
    altText: string;
    /**
     * String of external url, take member to this url when clicked.
     *
     * @generated from protobuf field: string external_url = 8;
     */
    externalUrl: string;
}
/**
 * @generated from protobuf enum verily_me.web.home.bff.api.v1.ContentCardSource
 */
export enum ContentCardSource {
    /**
     * @generated from protobuf enum value: CONTENT_CARD_SOURCE_UNSPECIFIED = 0;
     */
    CONTENT_CARD_SOURCE_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: WEB_MD_VERILY_HEALTH_CLIPS = 1;
     */
    WEB_MD_VERILY_HEALTH_CLIPS = 1,
    /**
     * @generated from protobuf enum value: WEB_MD_CONSUMER_HEALTH_LIBRARY = 2;
     */
    WEB_MD_CONSUMER_HEALTH_LIBRARY = 2,
    /**
     * @generated from protobuf enum value: WEB_MD = 3;
     */
    WEB_MD = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class ListContentCardsRequest$Type extends MessageType<ListContentCardsRequest> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.ListContentCardsRequest", [
            { no: 1, name: "language_tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListContentCardsRequest>): ListContentCardsRequest {
        const message = { languageTag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListContentCardsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListContentCardsRequest): ListContentCardsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string language_tag */ 1:
                    message.languageTag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListContentCardsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string language_tag = 1; */
        if (message.languageTag !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.languageTag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.ListContentCardsRequest
 */
export const ListContentCardsRequest = new ListContentCardsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListContentCardsResponse$Type extends MessageType<ListContentCardsResponse> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.ListContentCardsResponse", [
            { no: 1, name: "content_cards", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ContentCard }
        ]);
    }
    create(value?: PartialMessage<ListContentCardsResponse>): ListContentCardsResponse {
        const message = { contentCards: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListContentCardsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListContentCardsResponse): ListContentCardsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated verily_me.web.home.bff.api.v1.ContentCard content_cards */ 1:
                    message.contentCards.push(ContentCard.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListContentCardsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated verily_me.web.home.bff.api.v1.ContentCard content_cards = 1; */
        for (let i = 0; i < message.contentCards.length; i++)
            ContentCard.internalBinaryWrite(message.contentCards[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.ListContentCardsResponse
 */
export const ListContentCardsResponse = new ListContentCardsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordContentCardInteractionRequest$Type extends MessageType<RecordContentCardInteractionRequest> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.RecordContentCardInteractionRequest", [
            { no: 1, name: "content_card_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RecordContentCardInteractionRequest>): RecordContentCardInteractionRequest {
        const message = { contentCardId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordContentCardInteractionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordContentCardInteractionRequest): RecordContentCardInteractionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string content_card_id */ 1:
                    message.contentCardId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordContentCardInteractionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string content_card_id = 1; */
        if (message.contentCardId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.contentCardId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.RecordContentCardInteractionRequest
 */
export const RecordContentCardInteractionRequest = new RecordContentCardInteractionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordContentCardInteractionResponse$Type extends MessageType<RecordContentCardInteractionResponse> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.RecordContentCardInteractionResponse", []);
    }
    create(value?: PartialMessage<RecordContentCardInteractionResponse>): RecordContentCardInteractionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordContentCardInteractionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordContentCardInteractionResponse): RecordContentCardInteractionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RecordContentCardInteractionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.RecordContentCardInteractionResponse
 */
export const RecordContentCardInteractionResponse = new RecordContentCardInteractionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContentCard$Type extends MessageType<ContentCard> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.ContentCard", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "source_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "content_source", kind: "enum", T: () => ["verily_me.web.home.bff.api.v1.ContentCardSource", ContentCardSource] },
            { no: 5, name: "header_image", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "elapsed_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "alt_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "external_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContentCard>): ContentCard {
        const message = { id: "", title: "", sourceName: "", contentSource: 0, headerImage: new Uint8Array(0), elapsedTime: "", altText: "", externalUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContentCard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContentCard): ContentCard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string source_name */ 3:
                    message.sourceName = reader.string();
                    break;
                case /* verily_me.web.home.bff.api.v1.ContentCardSource content_source */ 4:
                    message.contentSource = reader.int32();
                    break;
                case /* bytes header_image */ 5:
                    message.headerImage = reader.bytes();
                    break;
                case /* string elapsed_time */ 6:
                    message.elapsedTime = reader.string();
                    break;
                case /* string alt_text */ 7:
                    message.altText = reader.string();
                    break;
                case /* string external_url */ 8:
                    message.externalUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContentCard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string source_name = 3; */
        if (message.sourceName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.sourceName);
        /* verily_me.web.home.bff.api.v1.ContentCardSource content_source = 4; */
        if (message.contentSource !== 0)
            writer.tag(4, WireType.Varint).int32(message.contentSource);
        /* bytes header_image = 5; */
        if (message.headerImage.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.headerImage);
        /* string elapsed_time = 6; */
        if (message.elapsedTime !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.elapsedTime);
        /* string alt_text = 7; */
        if (message.altText !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.altText);
        /* string external_url = 8; */
        if (message.externalUrl !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.externalUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.ContentCard
 */
export const ContentCard = new ContentCard$Type();
/**
 * @generated ServiceType for protobuf service verily_me.web.home.bff.api.v1.DiscoverService
 */
export const DiscoverService = new ServiceType("verily_me.web.home.bff.api.v1.DiscoverService", [
    { name: "ListContentCards", options: {}, I: ListContentCardsRequest, O: ListContentCardsResponse },
    { name: "RecordContentCardInteraction", options: {}, I: RecordContentCardInteractionRequest, O: RecordContentCardInteractionResponse }
]);
