import isEqual from 'lodash.isequal';
import {useRef, useState} from 'react';

/**
 * Custom React hook that only updates the state if the new value is deeply different
 * from the previous value using deep comparison.
 *
 * @template T - The type of the state value.
 *
 * @param {T} initialValue - The initial value of the state.
 *
 * @returns {[T, (newValue: T) => void]} - Returns an array with the current state value
 * and a function to update the state. The update function only updates the state if the new
 * value is deeply different from the current one.
 *
 * @example
 * const [state, setState] = useDeepCompareState({ name: 'Alice', age: 25 });
 *
 * // State will only update if the new object is deeply different.
 * setState({ name: 'Alice', age: 26 });
 */
function useDeepCompareState<T>(initialValue: T) {
  const [state, setState] = useState<T>(initialValue);
  const previousValueRef = useRef<T>(initialValue);

  const setDeepCompareState = (newValue: T) => {
    if (!isEqual(previousValueRef.current, newValue)) {
      previousValueRef.current = newValue;
      setState(newValue);
    }
  };

  return [state, setDeepCompareState] as const;
}

export default useDeepCompareState;
