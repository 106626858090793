import {Box, CircularProgress} from '@mui/material';
import {BundleUtility} from '@verily-src/bundle-utility';
import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import ErrorView from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorView/ErrorView';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

declare global {
  interface Window {
    BundleUtility: BundleUtility; // Allow Cypress to bundle.advance() for BDD
  }
}

function LoadingPage() {
  const {t} = useTranslation();
  const [hasWaitedTenSeconds, setHasWaitedTenSeconds] = useState(false);
  useEffect(() => {
    // Set page title on initial page load
    document.title = 'Verily Me';
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setHasWaitedTenSeconds(true);
    }, 10000);

    return () => clearTimeout(timer);
  });

  // Why not in <Root>?
  // Minimize the amount of time window is bloated with an extra property that is really only used
  // for BDD tests, and discourage use of BundleUtility via window versus the VerilyMeApi outside
  // of the BDD test
  useEffect(() => {
    window.BundleUtility = (api as typeof VerilyMeApi).bundle;
  }, []);

  if (hasWaitedTenSeconds)
    return (
      <ErrorView
        title={t('something-went-wrong')}
        subtitle={t('were-having-an-issue')}
        refreshText={t('refresh')}
      />
    );

  return (
    <Box
      sx={{
        position: 'absolute',
        inset: 0,
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
      }}
      data-testid="loading-box"
    >
      <CircularProgress aria-label={t('spinner-label')} />
    </Box>
  );
}

export default LoadingPage;
