import {onLogout} from '../utilities/logoutUtils';

export type CachedResult<T> = {cached: T | undefined; fresh: Promise<T>};

/* eslint-disable @typescript-eslint/no-explicit-any */

function createCache<V, K extends any[]>(
  asyncMethod: (...args: K) => Promise<V>
): {
  get: (...args: K) => CachedResult<V>;
  clearCache: () => void;
} {
  const cache: Map<string, V> = new Map<string, V>();

  // Custom replacer function to handle BigInt
  function bigIntReplacer(key: string, value: any) {
    return typeof value === 'bigint' ? value.toString() : value;
  }

  async function fetchFresh(...args: K): Promise<V> {
    const val = await asyncMethod(...args);
    cache.set(JSON.stringify(args, bigIntReplacer), val);
    return val;
  }

  function get(...args: K): CachedResult<V> {
    return {
      cached: cache.get(JSON.stringify(args, bigIntReplacer)),
      fresh: fetchFresh(...args),
    };
  }

  function clearCache(): void {
    cache.clear();
  }

  return {get, clearCache};
}

/**
 * Creates a session-based cache (clears cache on logout) for an asynchronous method.
 *
 * Warning: Using this function on methods with large argument sizes could cause performance issues, as this function utilizes JSON.stringify on the method's arguments.
 *
 * @template V - The type of the value returned by the asynchronous method.
 * @template K - The types of the arguments accepted by the asynchronous method (default: empty array []).
 *
 * @param asyncMethod - The asynchronous method to be cached.
 *
 * @returns An object with a 'get' method to retrieve cached results.
 *
 * @example
 * // Usage:
 * const sessionCache = createSessionCache<string, [string, number]>(async (arg1: string, arg2: number): string => {
 *   // Asynchronous method implementation
 *   // ...
 *   return result;
 * });
 *
 * // Retrieve cached result
 * const {cached, fresh} = sessionCache.get("value1", 42);
 */
export function createSessionCache<V, K extends any[] = []>(
  asyncMethod: (...args: K) => Promise<V>
): {
  get: (...args: K) => CachedResult<V>;
} {
  const {get, clearCache} = createCache<V, K>(asyncMethod);
  onLogout(clearCache);
  return {get};
}

/* eslint-enable @typescript-eslint/no-explicit-any */
