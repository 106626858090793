/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/home/bff/api/v1/configuration_service.proto" (package "verily_me.web.home.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ConfigurationService } from "./configuration_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { JiteConfiguration } from "./configuration_service";
import type { GetJiteConfigurationRequest } from "./configuration_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Configuration Service providing API access to Verily Me capability configuration for participant
 *
 * @generated from protobuf service verily_me.web.home.bff.api.v1.ConfigurationService
 */
export interface IConfigurationServiceClient {
    /**
     * Get the JITE configuration values for the participant user.
     *
     * @generated from protobuf rpc: GetJiteConfiguration(verily_me.web.home.bff.api.v1.GetJiteConfigurationRequest) returns (verily_me.web.home.bff.api.v1.JiteConfiguration);
     */
    getJiteConfiguration(input: GetJiteConfigurationRequest, options?: RpcOptions): UnaryCall<GetJiteConfigurationRequest, JiteConfiguration>;
}
/**
 * Configuration Service providing API access to Verily Me capability configuration for participant
 *
 * @generated from protobuf service verily_me.web.home.bff.api.v1.ConfigurationService
 */
export class ConfigurationServiceClient implements IConfigurationServiceClient, ServiceInfo {
    typeName = ConfigurationService.typeName;
    methods = ConfigurationService.methods;
    options = ConfigurationService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Get the JITE configuration values for the participant user.
     *
     * @generated from protobuf rpc: GetJiteConfiguration(verily_me.web.home.bff.api.v1.GetJiteConfigurationRequest) returns (verily_me.web.home.bff.api.v1.JiteConfiguration);
     */
    getJiteConfiguration(input: GetJiteConfigurationRequest, options?: RpcOptions): UnaryCall<GetJiteConfigurationRequest, JiteConfiguration> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetJiteConfigurationRequest, JiteConfiguration>("unary", this._transport, method, opt, input);
    }
}
