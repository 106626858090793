import {useTheme} from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {
  ProfileVerilyMeOutlinedIcon,
  Snackbar,
} from '@verily-src/react-design-system';
import {BundleStatus, type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {Card} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/card_service';
import {GreetingInfo} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/home_service';
import ErrorToast from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorToast/ErrorToast';
import ErrorView from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorView/ErrorView';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import {isAuthenticationError} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/isAuthenticationError';
import useDeepCompareState from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useDeepCompareState';
import useInterval from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useInterval';
import useIsMobile from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useIsMobile';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {navigateToUrl} from 'single-spa';
import TodoFeed from '../../components/TodoFeed/TodoFeed';
import CardService from '../../services/grpc/CardService';
import HomeService from '../../services/grpc/HomeService';
import logError from '../../utils/logError';
import './HomePage.css';

const LIST_CARDS_INTERVAL_MS = 5000;
const REDIRECT_SEARCH_PARAM = 'redirectReason';

function HomePage() {
  const {t} = useTranslation();
  const [searchParams] = useSearchParams();

  const [greetingInfo, setGreetingInfo] = useState<GreetingInfo | undefined>();
  const [cards, setCards] = useState<Card[]>([]);
  const [bundleIds, setBundleIds] = useDeepCompareState<string[]>([]);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [infoToast, setInfoToast] = useState<boolean>(false);
  const infoToastArea = useRef<HTMLDivElement>(null);
  const [isFeedLoading, setIsFeedLoading] = useState<boolean>(true);
  const [initialLoadFailed, setInitialLoadFailed] = useState<boolean>(false);

  const greetingMessageEnabled = config.getBoolean(
    'FEATURE_GREETING_ON_HOME_PAGE_ENABLED'
  );
  const batchGetBundlesOptEnabled = config.getBoolean(
    'FEATURE_BATCH_GET_BUNDLES_OPT_ENABLED'
  );

  useEffect(() => {
    // Fetch on initial page load
    listCards(true);
    greetingMessageEnabled && getGreetingInfo();
    // Set page title on initial page load
    document.title = `Verily Me | ${t('home-page-header')}`;

    const redirectReason = searchParams.get(REDIRECT_SEARCH_PARAM);
    if (redirectReason === 'task-done') {
      // Focus the info toast area to announce the toast to screen readers on-load
      if (infoToastArea.current) {
        infoToastArea.current.focus();
      }
      setInfoToast(true);
    }
  }, []);

  useEffect(() => {
    if (batchGetBundlesOptEnabled && bundleIds.length) {
      (api as typeof VerilyMeApi).bundle.batchGetBundles(bundleIds);
    }
  }, [bundleIds]);

  useEffect(() => {
    if (!batchGetBundlesOptEnabled && cards && cards.length) {
      (api as typeof VerilyMeApi).bundle.batchGetBundles(
        cards.map(card => card.bundleName)
      );
    }
  }, [cards]);

  // Fetch every 5 seconds after page load
  useInterval(listCards, LIST_CARDS_INTERVAL_MS);

  async function getGreetingInfo() {
    const {cached, fresh} = HomeService.getGreetingInfo();
    // Use the cached greeting info on initial load, if available
    if (cached) {
      setGreetingInfo(cached);
    }
    // Update state to use actual greeting info whenever available
    fresh.then(setGreetingInfo).catch(logError);
  }

  async function listCards(initialLoad?: boolean) {
    const {cached, fresh} = CardService.listTodoCards();
    if (initialLoad && !cached) setIsFeedLoading(true);
    // Use the cached Cards on initial load, if available
    if (initialLoad && cached) {
      updateCards(filterCompletedCards(cached));
    }
    // Update state to use actual Cards whenever available
    fresh.then(updateCards).catch(err => {
      logError(err);
      setErrorMessage(err.message);

      if (!isAuthenticationError(err)) {
        // Render the error page if the initial load fails
        if (initialLoad) setInitialLoadFailed(true);
        setErrorToast(true);
        setIsFeedLoading(false);
      }
    });
  }

  const updateCards = (cards: Card[]) => {
    setCards(cards);
    setBundleIds(cards.map(card => card.bundleName));
    // Render the main page after we successfully fetch data
    setInitialLoadFailed(false);
    setIsFeedLoading(false);
  };

  const filterCompletedCards = (cards: Card[]): Card[] => {
    const statuses = (
      api as typeof VerilyMeApi
    ).bundle.getUpdatedBundleStatuses();
    // Filter out any completed bundles
    return cards.filter(
      ({bundleName}) => statuses[bundleName] !== BundleStatus.COMPLETED
    );
  };

  const onCardClick = async (index: number) => {
    const card = cards[index];

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('bundle', card.bundleName);
    newUrl.pathname = '/me/bundle/start';

    navigateToUrl(newUrl.toString());
  };

  return (
    <Layout header={<HomePageHeader />}>
      <Grid container>
        {initialLoadFailed ? (
          <ErrorView
            title={t('something-went-wrong')}
            subtitle={t('were-having-an-issue')}
            refreshText={t('refresh')}
          />
        ) : (
          <>
            {greetingMessageEnabled && (
              <GreetingMessage greetingInfo={greetingInfo} />
            )}
            <TodoFeed
              isLoading={isFeedLoading}
              cards={cards}
              onClick={onCardClick}
            />
          </>
        )}
      </Grid>
      <div role="alert" aria-live="assertive" ref={infoToastArea} tabIndex={0}>
        {infoToast && (
          <Snackbar
            color={'general'}
            open={infoToast}
            onClose={() => setInfoToast(false)}
            text={t('completed-task')}
          />
        )}
      </div>
      <ErrorToast
        userFriendlyText={t('unknown-error-occurred')}
        errorMessage={errorMessage}
        open={errorToast}
        onClose={() => setErrorToast(false)}
      />
    </Layout>
  );
}

function HomePageHeader() {
  const {t} = useTranslation();
  const theme = useTheme();

  const isMobile = useIsMobile();

  return (
    <Header headerText={t('home-page-header')} hasBackButton={false}>
      {isMobile && (
        <IconButton
          aria-label="profile"
          href="/me/profile"
          sx={{
            // Padding to reach the MIN_TOUCH_TARGET_SIZE
            padding: '2px',
          }}
        >
          <div
            style={{
              borderColor: theme.palette.background.separator,
              borderWidth: 1,
              borderStyle: 'solid',
              borderRadius: '50%',
              width: 40,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ProfileVerilyMeOutlinedIcon
              sx={{
                '& path': {
                  fill: theme.palette.icon.default,
                },
              }}
            />
          </div>
        </IconButton>
      )}
    </Header>
  );
}

interface GreetingInfoProps {
  greetingInfo: GreetingInfo | undefined;
}

function GreetingMessage(props: GreetingInfoProps) {
  const {t} = useTranslation();
  const theme = useTheme();

  const {greetingInfo} = props;
  const name =
    greetingInfo?.userInfo?.preferredName ||
    t('greeting-message-fallback-name');

  const greetingMessage = t('greeting-message') + ' ' + name;
  return (
    <Typography
      data-testid="greeting"
      sx={{
        pl: 0.5,
        fontWeight: 500,
        color: theme.palette.text.default,
        marginBottom: 2,
        fontSize: '1.5rem',
      }}
      variant="body1"
    >
      {greetingMessage}
    </Typography>
  );
}

export default HomePage;
