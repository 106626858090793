/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/home/bff/api/v1/home_service.proto" (package "verily_me.web.home.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Request message for retrieving information to be displayed in the greeting message.
 *
 * @generated from protobuf message verily_me.web.home.bff.api.v1.GetGreetingInfoRequest
 */
export interface GetGreetingInfoRequest {
}
/**
 * Resource containing the information for greeting message.
 *
 * @generated from protobuf message verily_me.web.home.bff.api.v1.GreetingInfo
 */
export interface GreetingInfo {
    /**
     * Information of the participant.
     *
     * @generated from protobuf field: verily_me.web.home.bff.api.v1.UserInfo user_info = 1;
     */
    userInfo?: UserInfo;
}
/**
 * Resource representing the information of the participant.
 *
 * @generated from protobuf message verily_me.web.home.bff.api.v1.UserInfo
 */
export interface UserInfo {
    /**
     * Preferred name of the participant.
     *
     * @generated from protobuf field: string preferred_name = 1;
     */
    preferredName: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetGreetingInfoRequest$Type extends MessageType<GetGreetingInfoRequest> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.GetGreetingInfoRequest", []);
    }
    create(value?: PartialMessage<GetGreetingInfoRequest>): GetGreetingInfoRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetGreetingInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetGreetingInfoRequest): GetGreetingInfoRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetGreetingInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.GetGreetingInfoRequest
 */
export const GetGreetingInfoRequest = new GetGreetingInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GreetingInfo$Type extends MessageType<GreetingInfo> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.GreetingInfo", [
            { no: 1, name: "user_info", kind: "message", T: () => UserInfo }
        ]);
    }
    create(value?: PartialMessage<GreetingInfo>): GreetingInfo {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GreetingInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GreetingInfo): GreetingInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* verily_me.web.home.bff.api.v1.UserInfo user_info */ 1:
                    message.userInfo = UserInfo.internalBinaryRead(reader, reader.uint32(), options, message.userInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GreetingInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* verily_me.web.home.bff.api.v1.UserInfo user_info = 1; */
        if (message.userInfo)
            UserInfo.internalBinaryWrite(message.userInfo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.GreetingInfo
 */
export const GreetingInfo = new GreetingInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInfo$Type extends MessageType<UserInfo> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.UserInfo", [
            { no: 1, name: "preferred_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserInfo>): UserInfo {
        const message = { preferredName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserInfo): UserInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string preferred_name */ 1:
                    message.preferredName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string preferred_name = 1; */
        if (message.preferredName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.preferredName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.UserInfo
 */
export const UserInfo = new UserInfo$Type();
/**
 * @generated ServiceType for protobuf service verily_me.web.home.bff.api.v1.HomeService
 */
export const HomeService = new ServiceType("verily_me.web.home.bff.api.v1.HomeService", [
    { name: "GetGreetingInfo", options: {}, I: GetGreetingInfoRequest, O: GreetingInfo }
]);
