/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/home/bff/api/v1/card_service.proto" (package "verily_me.web.home.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.ListTodoCardsRequest
 */
export interface ListTodoCardsRequest {
    /**
     * BCP-47 language tag the task cards should be rendered in.
     *
     * @generated from protobuf field: string language_tag = 1;
     */
    languageTag: string;
}
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.ListTodoCardsResponse
 */
export interface ListTodoCardsResponse {
    /**
     * List of cards to be displayed on the Todo feed.
     *
     * @generated from protobuf field: repeated verily_me.web.home.bff.api.v1.Card cards = 1;
     */
    cards: Card[];
}
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.Card
 */
export interface Card {
    /**
     * Card content to display on the home page. Entry point into the bundle.
     *
     * @generated from protobuf field: verily_me.web.home.bff.api.v1.TaskCard task_card = 1;
     */
    taskCard?: TaskCard;
    /**
     * Bundle associated with this card
     *
     * @generated from protobuf field: string bundle_name = 2;
     */
    bundleName: string;
    /**
     * Bundle plan ID, different for each bundle, but the same across users (unlike bundle_name)
     *
     * @generated from protobuf field: string bundle_plan_id = 3;
     */
    bundlePlanId: string;
    /**
     * Bundle plan name, accompanies bundle_plan_id
     *
     * @generated from protobuf field: string bundle_plan_name = 4;
     */
    bundlePlanName: string;
}
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.TaskCard
 */
export interface TaskCard {
    /**
     * AIP name, eg /v1/taskcards/1/versions/1
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Title of the task card
     *
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * Subtitle of the task card
     *
     * @generated from protobuf field: string subtitle = 3;
     */
    subtitle: string;
    /**
     * HTML safe description of the task card
     *
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * Localized text associated with the bundle status.
     * Only populated if the bundle is in progress.
     * See https://screenshot.googleplex.com/4sZrBDZveLtgdAe.
     *
     * @generated from protobuf field: string status_text = 5;
     */
    statusText: string;
    /**
     * @generated from protobuf oneof: deadline
     */
    deadline: {
        oneofKind: "dueDate";
        /**
         * @generated from protobuf field: verily_me.web.home.bff.api.v1.DueDate due_date = 6;
         */
        dueDate: DueDate;
    } | {
        oneofKind: "overdueText";
        /**
         * A localized text for the bundle that's overdue.
         * See https://screenshot.googleplex.com/4eb9aGiDQ2Gwi9t.
         *
         * @generated from protobuf field: string overdue_text = 7;
         */
        overdueText: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * Type of illustration to show on the card.
     *
     * @generated from protobuf field: verily_me.web.home.bff.api.v1.TaskCard.IllustrationType illustration_type = 8;
     */
    illustrationType: TaskCard_IllustrationType;
}
/**
 * @generated from protobuf enum verily_me.web.home.bff.api.v1.TaskCard.IllustrationType
 */
export enum TaskCard_IllustrationType {
    /**
     * @generated from protobuf enum value: ILLUSTRATION_TYPE_UNSPECIFIED = 0;
     */
    ILLUSTRATION_TYPE_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: SURVEY = 1;
     */
    SURVEY = 1,
    /**
     * @generated from protobuf enum value: CONSENT = 2;
     */
    CONSENT = 2,
    /**
     * @generated from protobuf enum value: ID_VERIFICATION = 3;
     */
    ID_VERIFICATION = 3
}
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.DueDate
 */
export interface DueDate {
    /**
     * A localized label above the due date text, i.e. "Due date".
     * Not set if the bundle has no due date or it is overdue.
     * See https://screenshot.googleplex.com/Z8C8JbpnvsvuK66.
     *
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * A localized due date in relative day format, i.e. today, 3 days, a month.
     * Not set for bundles that do not have a due date.
     * See https://screenshot.googleplex.com/6PMWn8BTsqViKpS.
     *
     * @generated from protobuf field: string text = 2;
     */
    text: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class ListTodoCardsRequest$Type extends MessageType<ListTodoCardsRequest> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.ListTodoCardsRequest", [
            { no: 1, name: "language_tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListTodoCardsRequest>): ListTodoCardsRequest {
        const message = { languageTag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListTodoCardsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTodoCardsRequest): ListTodoCardsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string language_tag */ 1:
                    message.languageTag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTodoCardsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string language_tag = 1; */
        if (message.languageTag !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.languageTag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.ListTodoCardsRequest
 */
export const ListTodoCardsRequest = new ListTodoCardsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTodoCardsResponse$Type extends MessageType<ListTodoCardsResponse> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.ListTodoCardsResponse", [
            { no: 1, name: "cards", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Card }
        ]);
    }
    create(value?: PartialMessage<ListTodoCardsResponse>): ListTodoCardsResponse {
        const message = { cards: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListTodoCardsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTodoCardsResponse): ListTodoCardsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated verily_me.web.home.bff.api.v1.Card cards */ 1:
                    message.cards.push(Card.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTodoCardsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated verily_me.web.home.bff.api.v1.Card cards = 1; */
        for (let i = 0; i < message.cards.length; i++)
            Card.internalBinaryWrite(message.cards[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.ListTodoCardsResponse
 */
export const ListTodoCardsResponse = new ListTodoCardsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Card$Type extends MessageType<Card> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.Card", [
            { no: 1, name: "task_card", kind: "message", T: () => TaskCard },
            { no: 2, name: "bundle_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "bundle_plan_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "bundle_plan_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Card>): Card {
        const message = { bundleName: "", bundlePlanId: "", bundlePlanName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Card>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Card): Card {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* verily_me.web.home.bff.api.v1.TaskCard task_card */ 1:
                    message.taskCard = TaskCard.internalBinaryRead(reader, reader.uint32(), options, message.taskCard);
                    break;
                case /* string bundle_name */ 2:
                    message.bundleName = reader.string();
                    break;
                case /* string bundle_plan_id */ 3:
                    message.bundlePlanId = reader.string();
                    break;
                case /* string bundle_plan_name */ 4:
                    message.bundlePlanName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Card, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* verily_me.web.home.bff.api.v1.TaskCard task_card = 1; */
        if (message.taskCard)
            TaskCard.internalBinaryWrite(message.taskCard, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string bundle_name = 2; */
        if (message.bundleName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.bundleName);
        /* string bundle_plan_id = 3; */
        if (message.bundlePlanId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.bundlePlanId);
        /* string bundle_plan_name = 4; */
        if (message.bundlePlanName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.bundlePlanName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.Card
 */
export const Card = new Card$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskCard$Type extends MessageType<TaskCard> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.TaskCard", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "subtitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "status_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "due_date", kind: "message", oneof: "deadline", T: () => DueDate },
            { no: 7, name: "overdue_text", kind: "scalar", oneof: "deadline", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "illustration_type", kind: "enum", T: () => ["verily_me.web.home.bff.api.v1.TaskCard.IllustrationType", TaskCard_IllustrationType] }
        ]);
    }
    create(value?: PartialMessage<TaskCard>): TaskCard {
        const message = { name: "", title: "", subtitle: "", description: "", statusText: "", deadline: { oneofKind: undefined }, illustrationType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaskCard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskCard): TaskCard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string subtitle */ 3:
                    message.subtitle = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string status_text */ 5:
                    message.statusText = reader.string();
                    break;
                case /* verily_me.web.home.bff.api.v1.DueDate due_date */ 6:
                    message.deadline = {
                        oneofKind: "dueDate",
                        dueDate: DueDate.internalBinaryRead(reader, reader.uint32(), options, (message.deadline as any).dueDate)
                    };
                    break;
                case /* string overdue_text */ 7:
                    message.deadline = {
                        oneofKind: "overdueText",
                        overdueText: reader.string()
                    };
                    break;
                case /* verily_me.web.home.bff.api.v1.TaskCard.IllustrationType illustration_type */ 8:
                    message.illustrationType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaskCard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string subtitle = 3; */
        if (message.subtitle !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.subtitle);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string status_text = 5; */
        if (message.statusText !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.statusText);
        /* verily_me.web.home.bff.api.v1.DueDate due_date = 6; */
        if (message.deadline.oneofKind === "dueDate")
            DueDate.internalBinaryWrite(message.deadline.dueDate, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string overdue_text = 7; */
        if (message.deadline.oneofKind === "overdueText")
            writer.tag(7, WireType.LengthDelimited).string(message.deadline.overdueText);
        /* verily_me.web.home.bff.api.v1.TaskCard.IllustrationType illustration_type = 8; */
        if (message.illustrationType !== 0)
            writer.tag(8, WireType.Varint).int32(message.illustrationType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.TaskCard
 */
export const TaskCard = new TaskCard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DueDate$Type extends MessageType<DueDate> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.DueDate", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DueDate>): DueDate {
        const message = { label: "", text: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DueDate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DueDate): DueDate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* string text */ 2:
                    message.text = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DueDate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* string text = 2; */
        if (message.text !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.text);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.DueDate
 */
export const DueDate = new DueDate$Type();
/**
 * @generated ServiceType for protobuf service verily_me.web.home.bff.api.v1.CardService
 */
export const CardService = new ServiceType("verily_me.web.home.bff.api.v1.CardService", [
    { name: "ListTodoCards", options: {}, I: ListTodoCardsRequest, O: ListTodoCardsResponse }
]);
