/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/bundle/bff/api/v1/logger_service.proto" (package "verily_me.web.bundle.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { Empty } from "../../../../../../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.LogErrorRequest
 */
export interface LogErrorRequest {
    /**
     * @generated from protobuf field: verily_me.web.bundle.bff.api.v1.ErrorMessage message = 1;
     */
    message?: ErrorMessage;
    /**
     * The MFE component that the error message belongs to.
     *
     * @generated from protobuf field: string component_name = 2;
     */
    componentName: string;
    /**
     * The severity of the error.
     *
     * @generated from protobuf field: verily_me.web.bundle.bff.api.v1.LogErrorRequest.Severity severity = 3;
     */
    severity: LogErrorRequest_Severity;
}
/**
 * Error severity.
 *
 * @generated from protobuf enum verily_me.web.bundle.bff.api.v1.LogErrorRequest.Severity
 */
export enum LogErrorRequest_Severity {
    /**
     * Default value. This value is unused.
     *
     * @generated from protobuf enum value: SEVERITY_UNSPECIFIED = 0;
     */
    SEVERITY_UNSPECIFIED = 0,
    /**
     * Corresponds to GCP Error.
     *
     * @generated from protobuf enum value: ERROR = 1;
     */
    ERROR = 1,
    /**
     * Corresponds to GCP Warning.
     *
     * @generated from protobuf enum value: WARNING = 2;
     */
    WARNING = 2
}
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.ErrorMessage
 */
export interface ErrorMessage {
    /**
     * Error name.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Error stack trace.
     *
     * @generated from protobuf field: string stack = 2;
     */
    stack: string;
    /**
     * Location where the error occurred.
     *
     * @generated from protobuf field: string url = 3;
     */
    url: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class LogErrorRequest$Type extends MessageType<LogErrorRequest> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.LogErrorRequest", [
            { no: 1, name: "message", kind: "message", T: () => ErrorMessage },
            { no: 2, name: "component_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "severity", kind: "enum", T: () => ["verily_me.web.bundle.bff.api.v1.LogErrorRequest.Severity", LogErrorRequest_Severity] }
        ]);
    }
    create(value?: PartialMessage<LogErrorRequest>): LogErrorRequest {
        const message = { componentName: "", severity: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogErrorRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogErrorRequest): LogErrorRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* verily_me.web.bundle.bff.api.v1.ErrorMessage message */ 1:
                    message.message = ErrorMessage.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                case /* string component_name */ 2:
                    message.componentName = reader.string();
                    break;
                case /* verily_me.web.bundle.bff.api.v1.LogErrorRequest.Severity severity */ 3:
                    message.severity = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogErrorRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* verily_me.web.bundle.bff.api.v1.ErrorMessage message = 1; */
        if (message.message)
            ErrorMessage.internalBinaryWrite(message.message, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string component_name = 2; */
        if (message.componentName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.componentName);
        /* verily_me.web.bundle.bff.api.v1.LogErrorRequest.Severity severity = 3; */
        if (message.severity !== 0)
            writer.tag(3, WireType.Varint).int32(message.severity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.LogErrorRequest
 */
export const LogErrorRequest = new LogErrorRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ErrorMessage$Type extends MessageType<ErrorMessage> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.ErrorMessage", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "stack", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ErrorMessage>): ErrorMessage {
        const message = { name: "", stack: "", url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ErrorMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ErrorMessage): ErrorMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string stack */ 2:
                    message.stack = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ErrorMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string stack = 2; */
        if (message.stack !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stack);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.ErrorMessage
 */
export const ErrorMessage = new ErrorMessage$Type();
/**
 * @generated ServiceType for protobuf service verily_me.web.bundle.bff.api.v1.LoggerService
 */
export const LoggerService = new ServiceType("verily_me.web.bundle.bff.api.v1.LoggerService", [
    { name: "LogError", options: {}, I: LogErrorRequest, O: Empty }
]);
