import {Box, CircularProgress, Typography, useTheme} from '@mui/material';
import Grid from '@mui/material/Grid';
import {Theme} from '@mui/material/styles';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  Card as CardPb,
  TaskCard as TaskCardPb,
  TaskCard_IllustrationType,
} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/card_service';
import useIsMobile from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useIsMobile';
import {useTranslation} from 'react-i18next';
import ConsentImageV2 from '../../assets/task-consent-v2.svg';
import ConsentImage from '../../assets/task-consent.svg';
import DefaultImage from '../../assets/task-default.svg';
import SurveyImageV2 from '../../assets/task-survey-v2.svg';
import SurveyImage from '../../assets/task-survey.svg';
import VerificationImageV2 from '../../assets/task-verification-v2.svg';
import VerificationImage from '../../assets/task-verification.svg';
import {TaskCard} from '../TaskCard';
import {TodoFeedPlaceholderImage} from './TodoFeedPlaceholderImage';

interface TodoFeedProps {
  isLoading: boolean;
  onClick: (index: number) => void;
  cards: CardPb[];
}

/** Knows how to render task cards. */
function TodoFeed(props: TodoFeedProps) {
  const theme = useTheme();
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const skeletonsEnabled = config.getBoolean('FEATURE_SKELETONS_ENABLED');

  const isLoading = props.isLoading;
  const hasNoData = !isLoading && props.cards.length === 0;
  const hasData = !isLoading && props.cards.length > 0;

  if (isLoading) {
    return skeletonsEnabled ? (
      <Grid container spacing={isMobile ? 1 : 2}>
        {[...Array(2).keys()].map(idx => (
          <Grid
            item
            key={idx}
            xs={12}
            flexDirection={'column'}
            display={'flex'}
          >
            <TaskCard loading />
          </Grid>
        ))}
      </Grid>
    ) : (
      <CircularProgress
        aria-label={t('spinner-label')}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      />
    );
  }

  if (hasNoData) {
    return <EmptyContent />;
  }

  return (
    <Grid container spacing={isMobile ? 1 : 2} data-id="todofeed">
      {hasData &&
        props.cards.map((card, index) => {
          const {taskCard} = card;
          if (!taskCard) {
            return undefined;
          }
          return (
            <Grid
              item
              xs={12}
              key={card.bundleName}
              flexDirection={'column'}
              display={'flex'}
            >
              <TaskCard
                title={taskCard.title}
                onClick={() => props.onClick(index)}
                bundleId={card.bundleName}
                bundlePlanId={card.bundlePlanId}
                bundlePlanName={card.bundlePlanName}
                status={taskCard.statusText}
                cardIllustrationImage={getCardIllustrationImage(taskCard)}
                overdue={taskCard.deadline.oneofKind === 'overdueText'}
                dueDateLabel={getDueDateLabel(taskCard)}
                dueDate={getDueDateInDays(taskCard)}
                taskCardsBackgroundColor={getBackGroundColor(
                  theme,
                  taskCard.illustrationType
                )}
                overdueText={getOverdueText(taskCard)}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}

function EmptyContent() {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          m: 4,
          boxSizing: 'border-box',
          minWidth: '295px',
        }}
      >
        <TodoFeedPlaceholderImage />
      </Box>
      <Typography
        variant="display5"
        component="h2"
        // TODO(ONEVERILY-17471): Import Poppins `500` weight font & replace
        // `600` instances
        sx={{mb: 2, fontSize: isMobile ? 21 : undefined, fontWeight: 600}}
      >
        {t('no-new-tasks')}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          px: isMobile ? 3 : 4,
          color: theme.palette.text.muted,
        }}
      >
        {t('finished-for-now')}
      </Typography>
    </Box>
  );
}

export default TodoFeed;
export {EmptyContent};

function getImageHref(
  illustrationType: TaskCard_IllustrationType
): string | undefined {
  const isVDSThemeV2Enabled = config.getBoolean('FEATURE_VDS_THEME_V2_ENABLED');
  if (isVDSThemeV2Enabled) {
    switch (illustrationType) {
      case TaskCard_IllustrationType.SURVEY:
        return SurveyImageV2;
      case TaskCard_IllustrationType.CONSENT:
        return ConsentImageV2;
      case TaskCard_IllustrationType.ID_VERIFICATION:
        return VerificationImageV2;
      default:
        return undefined;
    }
  }

  switch (illustrationType) {
    case TaskCard_IllustrationType.SURVEY:
      return SurveyImage;
    case TaskCard_IllustrationType.CONSENT:
      return ConsentImage;
    case TaskCard_IllustrationType.ID_VERIFICATION:
      return VerificationImage;
    default:
      return DefaultImage;
  }
}

function getCardIllustrationImage(taskCard: TaskCardPb):
  | {
      altText: string;
      imageType: TaskCard_IllustrationType;
      href: string;
    }
  | undefined {
  const imageRef = getImageHref(taskCard.illustrationType);

  if (!imageRef) {
    return undefined;
  }

  return {
    altText: taskCard.title,
    imageType: taskCard.illustrationType,
    href: imageRef,
  };
}

function getBackGroundColor(
  theme: Theme,
  illustrationType: TaskCard_IllustrationType
): string | undefined {
  const isVDSThemeV2Enabled = config.getBoolean('FEATURE_VDS_THEME_V2_ENABLED');

  if (!isVDSThemeV2Enabled) {
    return theme.palette?.background?.paper;
  }
  switch (illustrationType) {
    case TaskCard_IllustrationType.SURVEY:
      return theme.palette?.verilyGreen?.mainVariant;
    case TaskCard_IllustrationType.CONSENT:
      return theme.palette?.brandCoral?.mainVariant;
    case TaskCard_IllustrationType.ID_VERIFICATION:
      return theme.palette?.brandLapis?.mainVariant;
    default:
      return theme.palette?.primary?.background;
  }
}

function getDueDateInDays(taskCard: TaskCardPb): string | undefined {
  if (taskCard.deadline.oneofKind === 'dueDate') {
    return taskCard.deadline.dueDate.text;
  }
  if (taskCard.deadline.oneofKind === 'overdueText') {
    return taskCard.deadline.overdueText;
  }

  return undefined;
}

function getDueDateLabel(taskCard: TaskCardPb): string | undefined {
  if (taskCard.deadline.oneofKind === 'dueDate') {
    return taskCard.deadline.dueDate.label;
  }

  return undefined;
}

function getOverdueText(taskCard: TaskCardPb): string | undefined {
  if (taskCard.deadline.oneofKind === 'overdueText') {
    return taskCard.deadline.overdueText;
  }

  return undefined;
}
