import {ThemeProvider} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers';
import {api} from '@verily-src/phaf-unified-api';
import {
  ConsumerLightTheme,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import DiscoverPage from '../../pages/DiscoverPage/DiscoverPage';
import HomePage from '../../pages/HomePage/HomePage';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import PageNotFound from '../../pages/PageNotFound/PageNotFound';
import ConfigurationService from '../../services/grpc/ConfigurationService';

export default function Root() {
  const [isJiteEnabled, setIsJiteEnabled] = useState<boolean>(true);

  const configurableCapabilitiesEnabled = config.getBoolean(
    'FEATURE_CONFIGURABLE_CAPABILITIES_ENABLED'
  );

  useEffect(() => {
    if (configurableCapabilitiesEnabled) initJiteEnabledGuard();
  }, []);

  async function initJiteEnabledGuard() {
    const {cached, fresh} = await ConfigurationService.getJiteConfiguration();
    if (cached && cached.enabled === false) {
      setIsJiteEnabled(false);
    } else {
      const isJiteEnabled = await fresh.then(response => response.enabled);
      if (!isJiteEnabled) {
        setIsJiteEnabled(false);
      }
    }
  }

  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  return (
    <ThemeProvider theme={{...theme, locale}}>
      <BrowserRouter>
        <Routes>
          <Route path="/me/home" element={<HomePage />}></Route>
          {isJiteEnabled && (
            <Route path="/me/discover" element={<DiscoverPage />}></Route>
          )}
          <Route path="/me/bundle/start" element={<LoadingPage />}></Route>
          <Route path="/me/auth" element={<LoadingPage />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
