/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/home/bff/api/v1/home_service.proto" (package "verily_me.web.home.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { HomeService } from "./home_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GreetingInfo } from "./home_service";
import type { GetGreetingInfoRequest } from "./home_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Home Service for retrieving information to be displayed on the home page.
 *
 * @generated from protobuf service verily_me.web.home.bff.api.v1.HomeService
 */
export interface IHomeServiceClient {
    /**
     * Returns information to be displayed in the greeting message that is placed on top
     * of the home page.
     *
     * @generated from protobuf rpc: GetGreetingInfo(verily_me.web.home.bff.api.v1.GetGreetingInfoRequest) returns (verily_me.web.home.bff.api.v1.GreetingInfo);
     */
    getGreetingInfo(input: GetGreetingInfoRequest, options?: RpcOptions): UnaryCall<GetGreetingInfoRequest, GreetingInfo>;
}
/**
 * Home Service for retrieving information to be displayed on the home page.
 *
 * @generated from protobuf service verily_me.web.home.bff.api.v1.HomeService
 */
export class HomeServiceClient implements IHomeServiceClient, ServiceInfo {
    typeName = HomeService.typeName;
    methods = HomeService.methods;
    options = HomeService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Returns information to be displayed in the greeting message that is placed on top
     * of the home page.
     *
     * @generated from protobuf rpc: GetGreetingInfo(verily_me.web.home.bff.api.v1.GetGreetingInfoRequest) returns (verily_me.web.home.bff.api.v1.GreetingInfo);
     */
    getGreetingInfo(input: GetGreetingInfoRequest, options?: RpcOptions): UnaryCall<GetGreetingInfoRequest, GreetingInfo> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetGreetingInfoRequest, GreetingInfo>("unary", this._transport, method, opt, input);
    }
}
