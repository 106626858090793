import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {RpcInterceptor} from '@protobuf-ts/runtime-rpc';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';

import {
  GetGreetingInfoRequest,
  GreetingInfo,
} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/home_service';
import {HomeServiceClient} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/home_service.client';
import {createSessionCache} from '@verily-src/verily1-verily-me-web-common-typescript/src/services/CachedEndpoint';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';
import {handleGrpcError} from './ErrorHandling';

let host = '/api';

if (process.env.NODE_ENV === 'local-development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '3000';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
}

class HomeService {
  private homeServiceClient;
  private greetingInfoCache = createSessionCache<GreetingInfo>(() =>
    this._getGreetingInfo()
  );

  async getClient() {
    if (!this.homeServiceClient) {
      const interceptors: RpcInterceptor[] = [
        await (api as typeof VerilyMeApi).auth.getInterceptor(),
      ];

      if (config.getBoolean('FEATURE_LOG_GRPC_REQUESTS_TO_CONSOLE_ENABLED')) {
        interceptors.push(new LogGrpcUnaryInterceptor());
      }

      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors,
      });

      this.homeServiceClient = new HomeServiceClient(transport);
    }

    return this.homeServiceClient;
  }

  getGreetingInfo = this.greetingInfoCache.get;

  private async _getGreetingInfo(): Promise<GreetingInfo> {
    const request: GetGreetingInfoRequest = {};
    const homeServiceClient = await this.getClient();

    try {
      return (await homeServiceClient.getGreetingInfo(request)).response;
    } catch (err) {
      throw handleGrpcError(err);
    }
  }
}

export default new HomeService();
