/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/home/bff/api/v1/configuration_service.proto" (package "verily_me.web.home.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.GetJiteConfigurationRequest
 */
export interface GetJiteConfigurationRequest {
}
/**
 * @generated from protobuf message verily_me.web.home.bff.api.v1.JiteConfiguration
 */
export interface JiteConfiguration {
    /**
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetJiteConfigurationRequest$Type extends MessageType<GetJiteConfigurationRequest> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.GetJiteConfigurationRequest", []);
    }
    create(value?: PartialMessage<GetJiteConfigurationRequest>): GetJiteConfigurationRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetJiteConfigurationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetJiteConfigurationRequest): GetJiteConfigurationRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetJiteConfigurationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.GetJiteConfigurationRequest
 */
export const GetJiteConfigurationRequest = new GetJiteConfigurationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JiteConfiguration$Type extends MessageType<JiteConfiguration> {
    constructor() {
        super("verily_me.web.home.bff.api.v1.JiteConfiguration", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<JiteConfiguration>): JiteConfiguration {
        const message = { enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JiteConfiguration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JiteConfiguration): JiteConfiguration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JiteConfiguration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.home.bff.api.v1.JiteConfiguration
 */
export const JiteConfiguration = new JiteConfiguration$Type();
/**
 * @generated ServiceType for protobuf service verily_me.web.home.bff.api.v1.ConfigurationService
 */
export const ConfigurationService = new ServiceType("verily_me.web.home.bff.api.v1.ConfigurationService", [
    { name: "GetJiteConfiguration", options: {}, I: GetJiteConfigurationRequest, O: JiteConfiguration }
]);
