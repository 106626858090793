/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/home/bff/api/v1/discover_service.proto" (package "verily_me.web.home.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { DiscoverService } from "./discover_service";
import type { RecordContentCardInteractionResponse } from "./discover_service";
import type { RecordContentCardInteractionRequest } from "./discover_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListContentCardsResponse } from "./discover_service";
import type { ListContentCardsRequest } from "./discover_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * //////////////////////// Potential Errors //////////////////////////
 * ListContentCards
 * Scenario: participant cannot be retrieved
 * Error: codes.NotFound
 * Scenario: participant has not properly enrolled
 * Error: codes.FailedPrecondition
 * Scenario: participant enrollment language cannot be parsed
 * Error: codes.FailedPrecondition
 * Scenario: content cards can not be properly made(cannot parse content card name, content card fields wrongly populated, etc)
 * Error: codes.Internal
 * Scenario: elapsed time cannot be calculated
 * Error: codes.Internal
 * ////////////////////////////////////////////////////////////////////
 *
 * @generated from protobuf service verily_me.web.home.bff.api.v1.DiscoverService
 */
export interface IDiscoverServiceClient {
    /**
     * @generated from protobuf rpc: ListContentCards(verily_me.web.home.bff.api.v1.ListContentCardsRequest) returns (verily_me.web.home.bff.api.v1.ListContentCardsResponse);
     */
    listContentCards(input: ListContentCardsRequest, options?: RpcOptions): UnaryCall<ListContentCardsRequest, ListContentCardsResponse>;
    /**
     * @generated from protobuf rpc: RecordContentCardInteraction(verily_me.web.home.bff.api.v1.RecordContentCardInteractionRequest) returns (verily_me.web.home.bff.api.v1.RecordContentCardInteractionResponse);
     */
    recordContentCardInteraction(input: RecordContentCardInteractionRequest, options?: RpcOptions): UnaryCall<RecordContentCardInteractionRequest, RecordContentCardInteractionResponse>;
}
/**
 * //////////////////////// Potential Errors //////////////////////////
 * ListContentCards
 * Scenario: participant cannot be retrieved
 * Error: codes.NotFound
 * Scenario: participant has not properly enrolled
 * Error: codes.FailedPrecondition
 * Scenario: participant enrollment language cannot be parsed
 * Error: codes.FailedPrecondition
 * Scenario: content cards can not be properly made(cannot parse content card name, content card fields wrongly populated, etc)
 * Error: codes.Internal
 * Scenario: elapsed time cannot be calculated
 * Error: codes.Internal
 * ////////////////////////////////////////////////////////////////////
 *
 * @generated from protobuf service verily_me.web.home.bff.api.v1.DiscoverService
 */
export class DiscoverServiceClient implements IDiscoverServiceClient, ServiceInfo {
    typeName = DiscoverService.typeName;
    methods = DiscoverService.methods;
    options = DiscoverService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListContentCards(verily_me.web.home.bff.api.v1.ListContentCardsRequest) returns (verily_me.web.home.bff.api.v1.ListContentCardsResponse);
     */
    listContentCards(input: ListContentCardsRequest, options?: RpcOptions): UnaryCall<ListContentCardsRequest, ListContentCardsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListContentCardsRequest, ListContentCardsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RecordContentCardInteraction(verily_me.web.home.bff.api.v1.RecordContentCardInteractionRequest) returns (verily_me.web.home.bff.api.v1.RecordContentCardInteractionResponse);
     */
    recordContentCardInteraction(input: RecordContentCardInteractionRequest, options?: RpcOptions): UnaryCall<RecordContentCardInteractionRequest, RecordContentCardInteractionResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<RecordContentCardInteractionRequest, RecordContentCardInteractionResponse>("unary", this._transport, method, opt, input);
    }
}
