import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {useEffect, useState} from 'react';

// TODO: When feature flag is removed, remove this
// class and replace with `import {Assistant} from '@verily-src/assistant-mfe';`
export function AsyncConversationalAssistantLoader() {
  const [conversationalAssistant, setConversationalAssistant] =
    useState<JSX.Element | null>(null);

  // TODO: Delete feature flag after merged to production
  const assistantEnabled = config.getBoolean('MULTITURN_ASSISTANT_ENABLED');

  useEffect(() => {
    async function loadConversationalAssistant() {
      const module = await System.import('@verily-src/assistant-mfe');
      setConversationalAssistant(<module.ConversationalAssistant />);
    }

    if (assistantEnabled) {
      loadConversationalAssistant();
    }
  }, []);

  if (!conversationalAssistant) {
    return null;
  }

  return conversationalAssistant;
}
